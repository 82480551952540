<template>
  <NavCheckAndConfirm :text="guardiansDescription" :backTo="backTo">
    <template></template>
  </NavCheckAndConfirm>
</template>

<script>
import NavCheckAndConfirm from '@/common/ui/NavCheckAndConfirm'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'

export default {
  name: 'GuardiansCheckAndConfirm',
  mixins: [willStringHelpers, sectionDescriptions],
  components: {
    NavCheckAndConfirm
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    backTo() {
      if (this.details.guardians_secondary_required)
        return '/guardians/secondary'
      return '/guardians/secondary_option'
    }
  },
  methods: {},
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('savePeople')
    this.$store.dispatch('saveDetails')
    this.$notification.success('Guaridans Saved')
    next()
  }
}
</script>

<style></style>
